<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 10:27:51
 * @LastEditTime: 2021-09-24 14:22:34
 * @LastEditors: aleaner
 * @Description: 商协会列表筛选
 * @FilePath: \gov\components\business-association-list\list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter
    :on-reset="onReset"
    :on-filter="onFilter"
    labelWidth="90px"
    :collapseHeight="exportData.tab === 'normal' ? 60 : 120"
    :toggleCollapseEnable="false"
  >
    <el-form
      class="filter-from"
      :model="form"
      :rules="rules"
      size="medium"
      ref="ruleForm"
      label-width="90px"
    >
      <div class="filter-item">
        <el-form-item label="组织名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入组织名称"></el-input>
        </el-form-item>
        <el-form-item label="客户端：" prop="type">
          <el-select size="medium" v-model="form.type" placeholder="请选择">
            <el-option
              v-for="item in typeOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="关联时间："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        start_time: "",
        end_time: "",
        name: "",
        type: "",
      },
      rules: {},
      typeOption: [
        { id: 0, name: "小程序" },
        { id: 1, name: "微网站" },
      ],
    };
  },
  methods: {
    //重置表单
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出
    onExport() {
      const postData = { ...this.exportData, ...this.form };
      // console.log("提交的数据", postData);
      // return orderDocument(postData).catch(() => {});
    },
  },
  created() {},
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped></style>
