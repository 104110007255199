<!--
 * @Author: mulingyuer
 * @Date: 2021-09-10 18:31:51
 * @LastEditTime: 2021-11-08 15:53:17
 * @LastEditors: aleaner
 * @Description: 商协会列表
 * @FilePath: \gov\views\business-association-list\list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page business-association-list">
    <div class="head">
      <ListFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <div class="content-tabs">
        <list-tabs
          v-model="postData.tab"
          :tabs="tabArr"
          @tab-click="handleTabClick"
        />
      </div>
      <el-table
        class="thead-light"
        :data="tbody"
        stripe
        style="width: 100%"
        @sort-change="sortChange"
      >
        <!-- 操作 -->
        <el-table-column
          :width="postData.tab === 'normal' ? 170 : 170"
          fixed="right"
          label="操作"
        >
          <template slot-scope="{ row }">
            <template v-if="postData.tab === 'normal'">
              <el-button type="text" size="mini" @click="cancelView(row.project_id)"
                >取消关联</el-button
              >
              <el-button type="text" size="mini" @click="onView(row.id)"
                >查看</el-button
              >
            </template>
            <template v-else>
              <el-button type="text" size="mini" @click="onCheckSuccess(row)"
                >通过</el-button
              >
              <el-button type="text" size="mini" @click="onClickError(row)"
                >不通过</el-button
              >
              <el-button type="text" size="mini" @click="onCheckView(row)"
                >查看</el-button
              >
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-for="th in thead[postData.tab]"
          :key="th.prop"
          :label="th.label"
          :prop="th.prop"
          :width="th.width"
          :min-width="th.minWidth"
          :sortable="th.sortable"
          :fixed="th.fixed"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <!-- logo -->
            <div
              v-if="['icon', 'logo', 'register_cert'].includes(th.prop)"
              class="logo cursor-pointer"
            >
              <img
                v-if="row[th.prop]"
                :src="fixImageUrl(row[th.prop])"
                @click="$previewImage([row[th.prop]])"
              />
              <span v-else>--</span>
            </div>
            <!-- 授权权限 -->
            <div v-else-if="th.prop === 'permission_texts'">
              <div
                class="tabel-list"
                v-for="(item, index) in row[th.prop]"
                :key="index"
              >
                <div class="list-dot"></div>
                {{ item }}
              </div>
            </div>
            <!-- 其他 -->
            <span v-else>
              {{ row[th.prop] | placeholder }}{{ th.suffix ? th.suffix : "" }}
              <el-button
                v-if="th.click"
                type="text"
                class="row-btn"
                @click="onClick(th.click, row)"
              >
                {{ th.click.name }}
              </el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />
    </div>
    <!-- 审核不通过弹窗 -->
    <UncheckDialog
      v-model="openUncheckDialog"
      :editData="uncheckDialogData"
      :complete="getList"
    />
    <!-- 审核查看弹窗 -->
    <CheckViewDialog
      v-model="openCheckViewDialog"
      :viewData="checkViewDialogData"
    />
    <!-- 修改星级弹窗 -->
    <ChangeStarDialog
      v-model="openChangeStarDialog"
      :editData="ChangeStarData"
      :complete="getList"
    />
  </div>
</template>

<script>
import ListFilter from "../../components/business-association-list/list/Filter.vue";
import UncheckDialog from "../../components/business-association-list/list/UncheckDialog.vue";
import ChangeStarDialog from "../../components/business-association-list/list/ChangeStarDialog.vue";
import CheckViewDialog from "../../components/business-association-list/list/CheckViewDialog.vue";
import Pagination from "@/base/components/Default/Pagination";
import {
  list,
  check,
  cancelAuth,
} from "../../api/business-association-list/list";
import { reductionMsgbox } from "@/base/shortcuts/msgbox";
import { objectFlat } from "@/base/utils/tool";
export default {
  data() {
    return {
      loading: false, //加载中
      postData: {
        tab: "normal",
      },
      tabArr: [
        { label: "已关联", name: "normal" },
        { label: "待审核", name: "checking", badge: 1 },
      ],
      thead: {
        normal: [
          { label: "ID", prop: "id", minWidth: 120 },
          { label: "LOGO", prop: "icon", minWidth: 120 },
          { label: "组织名称", prop: "name", minWidth: 120, tooltip: true },
          {
            label: "星级",
            prop: "star_text",
            minWidth: 130,
            click: { type: "edit_star", name: "修改" },
          },
          {
            label: "架构层级",
            prop: "level_text",
            minWidth: 150,
            click: { type: "view_level", name: "查看" },
          },
          {
            label: "会员人数",
            prop: "members_count",
            minWidth: 150,
            click: { type: "view_members_count", name: "详情" },
          },
          // { label: "资金规模", prop: "account", minWidth: 120 },
          // { label: "地区", prop: "account", minWidth: 120 },
          // { label: "行业类型", prop: "account", minWidth: 120 },
          // { label: "组织标识", prop: "account", minWidth: 120 },
          {
            label: "资讯数量",
            prop: "articles_count",
            minWidth: 150,
            suffix: "个",
            click: { type: "view_articles_count", name: "详情" },
          },
          {
            label: "资源数量",
            prop: "circles_count",
            minWidth: 150,
            suffix: "个",
            click: { type: "view_circles_count", name: "详情" },
          },
          {
            label: "企业数量",
            prop: "companies_count",
            minWidth: 150,
            suffix: "家",
            click: { type: "view_companies_count", name: "详情" },
          },
          {
            label: "活动数量",
            prop: "activities_count",
            minWidth: 150,
            suffix: "个",
            click: { type: "view_activities_count", name: "详情" },
          },
          { label: "是否收会费", prop: "member_fee_text", minWidth: 120 },
          { label: "最高会费", prop: "max_fee", minWidth: 120, suffix: "元" },
          { label: "最低会费", prop: "min_fee", minWidth: 120, suffix: "元" },
          {
            label: "会费收入",
            prop: "member_fee_income",
            minWidth: 150,
            suffix: "元",
            click: { type: "view_member_fee_income", name: "详情" },
          },
          {
            label: "增值服务收入",
            prop: "added_service_income",
            minWidth: 150,
            suffix: "元",
            click: { type: "view_added_service_income", name: "详情" },
          },
          { label: "授权权限", prop: "permission_texts", minWidth: 150 },
          { label: "授权时间", prop: "create_time", minWidth: 160 },
        ],
        checking: [
          { label: "ID", prop: "id", minWidth: 120 },
          { label: "LOGO", prop: "logo", minWidth: 120 },
          { label: "组织名称", prop: "name", minWidth: 120, tooltip: true },
          { label: "资金规模", prop: "funds_text", minWidth: 130 },
          { label: "地区", prop: "address", minWidth: 130 },
          // { label: "行业类型", prop: "icon", minWidth: 120 },
          { label: "组织标识", prop: "type_text", minWidth: 130 },
          { label: "授权权限", prop: "permission_texts", minWidth: 150 },
          {
            label: "社会团体法人登记证书",
            prop: "register_cert",
            minWidth: 180,
          },
        ],
      },
      tbody: [],
      pageData: {},
      openUncheckDialog: false, //是否显示不通过弹窗
      uncheckDialogData: "", //不通过的id
      openCheckViewDialog: false, //是否显示审核查看弹窗
      checkViewDialogData: {}, //审核查看的数据
      openChangeStarDialog: false, // 是否显示修改星级弹窗
      ChangeStarData: {}, // 修改星级的数据
    };
  },
  methods: {
    //获取列表数据
    getList(pageData) {
      this.loading = true;
      return list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //tab切换事件
    handleTabClick() {
      //tab name已经v-model绑定到postData
      Object.assign(this.postData, { page: 1 });
      this.getList();
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      return this.getList();
    },
    //排序
    sortChange({ prop, order }) {
      //prop是排序字段，order是状态字符
      const is_desc = order === "ascending" ? 0 : 1;
      Object.assign(this.postData, { order_by: prop, is_desc }, { page: 1 });
      this.getList();
    },
    //分页变化事件
    changPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
    // 取消关联
    cancelView(id) {
      this.$confirm("是否取消关联?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelAuth({ id }).then((res) => {
          this.$message.success(res.msg);
          this.getList();
        });
      });
    },
    //查看
    onView(id) {
      this.$router.push({
        name: "BusinessAssociationDetail",
        params: { detailId: id },
      });
    },
    //通用点击事件
    onClick({ type }, row) {
      switch (type) {
        case "edit_star": // 修改星级
          this.editStar(row);
          break;
        case "view_level": //查看层级架构图
          this.viewLeve(row);
          break;
        case "view_members_count": //会员数量
          this.$router.push({
            name: "DataListMemberLibrary",
            query: { projectId: row.id },
          });
          break;
        case "view_articles_count": //资讯数量
          this.$router.push({
            name: "DataListNewsLibrary",
            query: { projectId: row.id },
          });
          break;
        case "view_circles_count": //资源数量
          this.$router.push({
            name: "DataListResourceLibrary",
            query: { projectId: row.id },
          });
          break;
        case "view_companies_count": //企业数量
          this.$router.push({
            name: "DataListEnterpriseLibrary",
            query: { projectId: row.id },
          });
          break;
        case "view_activities_count": //活动数量
          this.$router.push({
            name: "DataListActivityLibrary",
            query: { projectId: row.id },
          });
          break;
        default:
          this.$message.info("开发中...");
      }
    },
    //查看架构层级图
    viewLeve({ framework_image }) {
      if (framework_image) {
        this.$previewImage([framework_image]);
      } else {
        this.$message.info("未上传架构图");
      }
    },
    // 修改星级
    editStar(row) {
      this.ChangeStarData = {
        id: row.id,
        star: row.star,
      };
      this.openChangeStarDialog = true;
    },
    //审核通过
    onCheckSuccess(row) {
      reductionMsgbox({
        message: `确定与 “${row.name}” 关联吗？`,
        confirmCallback: () => {
          return new Promise((resolve, reject) => {
            const postData = { id: row.id };
            check(postData)
              .then((res) => {
                const { msg } = res;
                this.$message.success(msg);

                this.getList();
                return resolve(true);
              })
              .catch(() => {
                return resolve(true);
              });
          });
        },
      });
    },
    //审核不通过
    onClickError(row) {
      this.uncheckDialogData = row.id;
      this.openUncheckDialog = true;
    },
    //审核查看
    onCheckView(row) {
      this.checkViewDialogData = objectFlat(row);
      this.openCheckViewDialog = true;
    },
  },
  created() {
    this.getList();
  },
  components: {
    ListFilter,
    Pagination,
    UncheckDialog,
    CheckViewDialog,
    ChangeStarDialog,
  },
};
</script>

<style lang="scss" scoped>
.business-association-list {
  .content {
    .logo {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        vertical-align: top;
      }
    }
    .row-btn {
      margin-left: 10px;
      padding: 0;
    }
  }
}

.tabel-list {
  display: flex;
  align-items: center;
  .list-dot {
    margin-right: 8px;
    background-color: #606266;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
