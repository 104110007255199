<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 16:09:07
 * @LastEditTime: 2021-09-29 15:15:30
 * @LastEditors: aleaner
 * @Description: 查看详情
 * @FilePath: \gov\views\business-association-list\detail.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="detail">
    <div class="content" v-loading="loading">
      <!-- 组织信息 -->
      <OrganizeInformation :data="OIData" :refreshData="getDetail" />
      <!-- 历届情况 -->
      <PreviousSituation :tbody="tbody" />
    </div>
    <FixedActionBar>
      <el-button size="medium" @click="onBack">返回</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import OrganizeInformation from "../../components/business-association-list/detail/OrganizeInformation.vue";
import PreviousSituation from "../../components/business-association-list/detail/PreviousSituation.vue";
import { detail } from "../../api/business-association-list/detail";
import { objectFilter, objectFlat } from "@/base/utils/tool";
export default {
  props: {
    //详情id
    detailId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loading: false, //加载中
      detailData: {}, //详情数据
      //扁平化组织信息
      OIData: {
        id: "",
        icon: "",
        name: "",
        level_text: 0,
        framework_image: "",
        members_count: 0,
        articles_count: 0,
        circles_count: 0,
        companies_count: 0,
        activities_count: 0,
        member_fee_text: "",
        max_fee: 0,
        min_fee: 0,
        member_fee_income: 0,
        added_service_income: 0,
      },
      tbody: [],
    };
  },
  methods: {
    //返回
    onBack() {
      this.$router.go(-1);
    },
    //获取详情
    getDetail() {
      this.loading = true;
      const postData = { id: this.detailId };
      detail(postData)
        .then((res) => {
          const { data } = res;
          this.detailData = data;

          this.initData();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //初始化数据
    initData() {
      this.OIData = objectFlat(objectFilter(this.detailData, "term_records"));
      this.tbody = this.detailData.term_records;
    },
  },
  created() {
    this.getDetail();
  },
  components: {
    FixedActionBar,
    OrganizeInformation,
    PreviousSituation,
  },
};
</script>

<style lang="scss" scoped>
</style>
