<!--
 * @Author: mulingyuer
 * @Date: 2021-04-27 11:51:46
 * @LastEditTime: 2021-09-23 14:44:26
 * @LastEditors: aleaner
 * @Description: 审核不通过弹窗
 * @FilePath: \gov\components\business-association-list\list\UncheckDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="审核不通过" :visible.sync="show" width="500px" class="dialog-vertical uncheck-dialog"
    @close="onClose('ruleForm')">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="0px" size="medium"
        class="medium-form">
        <el-form-item prop="reason">
          <el-input v-model="form.reason" type="textarea" rows="5" placeholder="请输入不通过的原因"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="medium" @click="show=false">取消
      </el-button>
      <el-button type="primary" size="medium" :loading="saveLoading" @click="onSave('ruleForm')">确定
      </el-button>
    </template>
  </el-dialog>
</template>
<script>
import { uncheck } from "../../../api/business-association-list/list";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //审核id：id、[id]
    editData: {
      type: [String, Number],
      required: true,
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        reason: "", //不通过的原因
      },
      rules: {
        reason: [
          { required: true, message: "请输入不通过的原因", trigger: "blur" },
        ],
      },
      saveLoading: false, //保存中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = {
            id: this.editData,
            reason: this.form.reason,
          };
          uncheck(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.saveLoading = false;
              this.show = false;
              this.complete();
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //关闭后的回调
    onClose(formName) {
      this.resetForm(formName);
    },
  },
};
</script>
<style lang="scss" scoped>
.uncheck-dialog {
  .content {
    padding-top: 15px;
    .el-textarea {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>