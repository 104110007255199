/*
 * @Author: mulingyuer
 * @Date: 2021-09-13 15:20:42
 * @LastEditTime: 2021-09-29 14:38:15
 * @LastEditors: aleaner
 * @Description: 商协会列表
 * @FilePath: \gov\api\business-association-list\list.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//架构层级选项数据
export const levelOptions = data => {
  return api({
    url: "/admin/gov/project/levels",
    method: "post",
    data
  })
}
//取消关联
export const cancelAuth = data => {
  return api({
    url: "/admin/gov/project/cancelAuth",
    method: "post",
    data
  })
}


//列表
export const list = data => {
  return api({
    url: "/admin/gov/project/index",
    method: "post",
    data
  })
}


//审核通过
export const check = data => {
  return api({
    url: "/admin/gov/project/check",
    method: "post",
    data
  })
}

//审核不通过
export const uncheck = data => {
  return api({
    url: "/admin/gov/project/uncheck",
    method: "post",
    data
  })
}

// 修改星级
export const setStar = data => {
  return api({
    url: "/admin/gov/project/setStar",
    method: "post",
    data
  })
}