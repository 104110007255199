/*
 * @Author: mulingyuer
 * @Date: 2021-09-13 15:20:42
 * @LastEditTime: 2021-09-29 14:38:15
 * @LastEditors: aleaner
 * @Description: 商协会列表
 * @FilePath: \gov\api\business-association-list\list.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//列表
export const  govPlatformList = data => {
  return api({
    url: "/admin/gov/govPlatform/index",
    method: "post",
    data
  })
}

//保存
export const  govPlatformSave = data => {
  return api({
    url: "/admin/gov/govPlatform/save",
    method: "post",
    data
  })
}
//软删除
export const  softDelete = data => {
  return api({
    url: "/admin/gov/govPlatform/softDelete",
    method: "post",
    data
  })
}
//还原
export const  putBack = data => {
  return api({
    url: "/admin/gov/govPlatform/putBack",
    method: "post",
    data
  })
}

//真删除
export const  govDelete = data => {
  return api({
    url: "/admin/gov/govPlatform/delete",
    method: "post",
    data
  })
}


