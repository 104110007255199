/*
 * @Author: mulingyuer
 * @Date: 2021-09-13 16:21:20
 * @LastEditTime: 2021-09-23 14:45:03
 * @LastEditors: aleaner
 * @Description:详情
 * @FilePath: \gov\api\business-association-list\detail.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//详情数据
export const detail = data => {
  return api({
    url: "/admin/gov/project/detail",
    method: "post",
    data
  })
}