<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 10:27:51
 * @LastEditTime: 2021-09-24 14:22:34
 * @LastEditors: aleaner
 * @Description: 商协会列表筛选
 * @FilePath: \gov\components\business-association-list\list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :on-reset="onReset" :on-filter="onFilter" :on-export="onExport" labelWidth="90px"
    :collapseHeight="exportData.tab === 'normal'?60:120" :toggleCollapseEnable="false">
    <el-form class="filter-from" :model="form" :rules="rules" size="medium" ref="ruleForm" label-width="90px">
      <div class="filter-item" v-show="exportData.tab === 'normal'">
        <el-form-item label="组织名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入组织名称"></el-input>
        </el-form-item>
        <el-form-item label="架构层级：" prop="level">
          <el-select v-model="form.level" placeholder="请选择架构层级">
            <el-option v-for="op in levelArr" :key="op.level" :label="op.name" :value="op.level">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否收会费：" prop="member_fee">
          <el-select v-model="form.member_fee" placeholder="请选择架构层级">
            <el-option v-for="op in memberFeeArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item" v-show="exportData.tab === 'checking'">
        <el-form-item label="组织名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入组织名称"></el-input>
        </el-form-item>
        <el-form-item label="资金规模：" prop="funds">
          <el-select v-model="form.funds" placeholder="请选择资金规模">
            <el-option v-for="op in fundsArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地区：" prop="address">
          <el-cascader :options="addressArr" v-model="form.address" :props="optionProp" placeholder="请选择地区"
            @change="handleChange">
          </el-cascader>
        </el-form-item>
      </div>
      <div class="filter-item" v-show="exportData.tab === 'checking'">
        <el-form-item label="组织标识：" prop="type">
          <el-select v-model="form.type" placeholder="请选择组织标识">
            <el-option v-for="op in typeArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- <div class="filter-item" v-if="exportData.tab === 'normal'">
        <el-form-item label="资金规模：" prop="funds">
          <el-select v-model="form.funds" placeholder="请选择资金规模">
            <el-option v-for="op in fundsArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地区：" prop="address">
          <el-cascader :options="addressArr" v-model="form.address" :props="optionProp" placeholder="请选择地区"
            @change="handleChange">
          </el-cascader>
        </el-form-item>
        <el-form-item label="组织标识：" prop="type">
          <el-select v-model="form.type" placeholder="请选择组织标识">
            <el-option v-for="op in typeArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div> -->
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import { levelOptions } from "../../../api/business-association-list/list";
const regionData = require("@/modules/common/json/address.json");
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        keyword: "", //组织名称
        level: -1, //架构层级
        member_fee: -1, //是否收会费
        funds: -1, // 资金规模
        address: "", // 地区
        type: -1, // 组织标识
      },
      rules: {},
      //层级架构选项数据
      levelArr: [{ name: "全部", level: -1 }],
      //是否收会费选项数据
      memberFeeArr: [
        { name: "全部", id: -1 },
        { name: "是", id: 1 },
        { name: "否", id: 0 },
      ],
      // 资金规模选项
      fundsArr: [
        { name: "全部", id: -1 },
        { name: "未设置", id: 0 },
        { name: "10万以下", id: 10 },
        { name: "10 ~ 50万", id: 50 },
        { name: "50 ~ 100万", id: 100 },
      ],
      // 组织标识选项数据
      typeArr: [
        { name: "全部", id: -1 },
        { name: "未选择", id: 0 },
        { name: "志愿者服务组织", id: 1 },
        { name: "行业协会商会", id: 2 },
        { name: "慈善组织", id: 3 },
        { name: "公开募捐资格", id: 4 },
      ],
      // 地区选项
      addressArr: regionData,
      optionProp: {
        expandTrigger: "hover",
        label: "label",
        value: "label",
      },
    };
  },
  methods: {
    //获取层级架构选项数据
    getLevelOptions() {
      levelOptions()
        .then((res) => {
          const { data } = res;
          this.levelArr = data;
        })
        .catch(() => {});
    },
    // 点击选择地区
    handleChange(e) {
      this.form.address = e.join(",");
    },
    //重置表单
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出
    onExport() {
      const postData = { ...this.exportData, ...this.form };
      // console.log("提交的数据", postData);
      // return orderDocument(postData).catch(() => {});
    },
  },
  created() {
    this.getLevelOptions();
  },
  components: {
    LayoutFilter,
  },
};
</script>

<style lang="scss" scoped>
</style>