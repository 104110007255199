<template>
  <div class="content-box">
    <el-dialog
      :title="form.id ? '编辑关联' : '添加关联'"
      :visible="is_show"
      width="700px"
      class="dialog-vertical"
      @close="close()"
      @open="openFn"
    >
      <el-form
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="120px"
        ref="form"
        @submit.native.prevent="() => {}"
      >
        <el-form-item prop="type" label="客户端：">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">小程序</el-radio>
            <el-radio :label="1">微网站</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="name" label="组织名称：">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="星级：">
          <el-select size="medium" v-model="form.star" placeholder="请选择">
            <el-option
              v-for="item in starOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="app_id" label="AppID：">
          <el-input v-model="form.app_id" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.type === 0"
          prop="path"
          label="小程序页面链接："
        >
          <el-input v-model="form.path" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item v-else prop="url" label="网页链接：">
          <el-input v-model="form.url" placeholder="请输入"></el-input>
          <div class="tips">
            小程序跳转H5微网站需要先添加业务，<a href="https://mp.weixin.qq.com/" target="_brank">请前往添加</a>
          </div>
        </el-form-item>
        <el-form-item label="logo：" prop="logo">
          <single-media-wall
            v-model="form.logo"
            width="136px"
            height="136px"
            ratio="136:136"
            fit="contain"
          >
          </single-media-wall>
          <div class="form-tip" style="margin-top: 10px">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #b3b3b3"
            ></span>
            <span style="font-size: 14px; color: #b3b3b3; margin-left: 4px"
              >推荐尺寸：136px * 136px</span
            >
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="saveLoading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import { govPlatformSave } from "../../api/gov-platform/list";
export default {
  name: "EntranceDialog",
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      default: {},
    },
  },
  components: { SingleMediaWall },
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  watch: {},

  data() {
    return {
      form: {
        id: 0,
        type: 0,
        logo: "",
      },
      saveLoading: false,
      rules: {
        name: [{ required: true, message: "请输入组织名称", trigger: "blur" }],
        app_id: [{ required: true, message: "AppID", trigger: "blur" }],

        path: [
          { required: true, message: "请输入小程序页面链接", trigger: "blur" },
        ],

        url: [{ required: true, message: "请输入网页链接", trigger: "blur" }],
        logo: [{ required: true, message: "请上传logo", trigger: "blur" }],
      },
      loading: false,
      starOption: [
        { id: 5, name: "五星商会" },
        { id: 4, name: "四星商会" },
        { id: 3, name: "三星商会" },
        { id: 2, name: "二星商会" },
        { id: 1, name: "一星商会" },
      ],
    };
  },
  methods: {
    openFn() {
      this.form = { ...this.$props.currentRow };
      console.log(this.$props.currentRow);
    },
    close() {
      this.is_show = false;
      this.$refs["form"].resetFields();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);
          this.saveLoading = true;
          govPlatformSave(this.form)
            .then((res) => {
              this.$message.success(res.msg);
              this.saveLoading = false;
              this.close();
              this.$emit("refreshList");
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  font-size: 14px;
  color: #b3b3b3;
  a {
    color: #3576ff;
    
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
  }
}
</style>
