<template>
  <div>
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :tab-arr="tabArr"
      :thead="thead"
      :on-fetch="getList"
      :on-delete="onDelete"
      :on-put-back="onPutBack"
      :on-toggle="toggleItem"
      @command="handleOperation"
    >
      <template slot="top">
        <el-button class="top-btn" type="primary" size="small" @click="toAdd"
          >添加关联</el-button
        >
      </template>
      <template #filter>
        <listFilter :export-data="{}" :filter-change="onFilter" />
      </template>
      <template slot="item_star" slot-scope="{ row }">
        <span v-if="row.star === 1">一星商会</span>
        <span v-else-if="row.star === 2">二星商会</span>
        <span v-else-if="row.star === 3">三星商会</span>
        <span v-else-if="row.star === 4">四星商会</span>
        <span v-else-if="row.star === 5">五星商会</span>
        <span v-else>--</span>
      </template>
    </list-layout>
    <!-- 关联弹窗 -->
    <AddCorrelationDialog @refreshList="refreshList" v-model="oepnAddCorrelation" :currentRow="currentRow"></AddCorrelationDialog>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";

import {
  govPlatformList,
  softDelete,
  putBack,
  govDelete,
} from "../../api/gov-platform/list";
import listFilter from "../../components/gov-platform/Filter";
import AddCorrelationDialog from '../../components/gov-platform/AddCorrelationDialog.vue'
export default {
  components: { listFilter, ListLayout,AddCorrelationDialog },
  data() {
    return {
      filterForm: {
        tab: "normal",
      },
      tabArr: [
        { label: "已关联", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      thead: [
        {
          label: "logo",
          prop: "logo",
          type: "cover",
          width: 100,
        },
        {
          label: "组织名称",
          prop: "name",
        },
        {
          label: "星级",
          prop: "star",
          type: "slot",
        },
        {
          label: "客户端",
          prop: "type_text",
        },
        {
          label: "关联时间",
          prop: "create_time",
          sortable: true,
          width: 200,

        },
        {
          label: "操作",
          type: "operation",
          // width: 135,
          width: 175,
          operations: [
            {
              command: "edit",
              text: "编辑",
              visible: (r, q) => q.tab !== "deleted",
            },
            { command: "putback", visible: (r, q) => q.tab === "deleted" },
            { command: "delete" },
          ],
        },
      ],
      // 修改星级数据
      ChangeStarData: {},
      // 关联弹窗
      oepnAddCorrelation:false,
      currentRow:{id:0,type:0,logo:""}
    };
  },
  methods: {
    getList(q = {}) {
      return govPlatformList({ ...this.filterForm, ...q });
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
    toggleItem(data) {
      return updateTemplateStatus(data);
    },
    // 数据删除
    onDelete(idList, forever = false) {
      console.log(idList)
      return forever ? govDelete({id:idList}) : softDelete({id:idList});
    },
    onPutBack(idList) {
      return putBack({id:idList});
    },
    handleOperation(e) {
      switch (e.command) {
        case "edit": {
          this.currentRow = e.row
          this.oepnAddCorrelation = true
          break;
        }
        default:
      }
    },
    // 添加第三方弹窗
    toAdd() {
      this.currentRow = {id:0,type:0,logo:""}
      this.oepnAddCorrelation = true

    },
    // 筛选回调
    onFilter(filterObj) {
      Object.assign(this.filterForm, filterObj, { page: 1 });
      this.$refs.listLayout.getList();
    },

  },
};
</script>

<style lang="scss" scoped></style>
