<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 14:12:52
 * @LastEditTime: 2021-09-24 14:35:36
 * @LastEditors: aleaner
 * @Description: 审核查看
 * @FilePath: \gov\components\business-association-list\list\CheckViewDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="查看" :visible.sync="show" width="500px" class="dialog-vertical check-view-dialog">
    <div class="content">
      <div class="item" v-for="item in keys" :key="item.key">
        <div class="label">{{item.label}}：</div>
        <!-- logo -->
        <div v-if="item.key==='logo'" class="value">
          <CertView :src="fixImageUrl(viewData[item.key])" width="150px" height="80px" />
        </div>
        <div v-else-if="item.key==='register_cert'" class="value">
          <CertView :src="fixImageUrl(viewData[item.key])" width="120px" height="200px" />
        </div>
        <div v-else class="value">{{viewData[item.key]|placeholder}}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CertView from "@/base/components/Preview/CertView.vue";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //查看的数据
    viewData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      keys: [
        { label: "LOGO", key: "logo" },
        { label: "组织名称", key: "name" },
        { label: "简介", key: "description" },
        { label: "现任会长", key: "president_name" },
        { label: "成立时间", key: "create_time" },
        { label: "资金规模", key: "funds_text" },
        { label: "所在地区", key: "address" },
        // {label:"行业类型",key:""},
        { label: "组织标识", key: "type_text" },
        { label: "登记证书", key: "register_cert" },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  components: {
    CertView,
  },
};
</script>

<style lang="scss" scoped>
.check-view-dialog {
  .content {
    padding: 10px 0;
    .item {
      display: flex;
      font-size: 14px;
      line-height: 22px;
      & + .item {
        margin-top: 20px;
      }
      .label {
        width: 100px;
        flex-shrink: 0;
        color: #999;
        text-align: right;
      }
      .value {
        width: 0;
        flex-grow: 1;
        color: #303133;
      }
    }
  }
}
</style>
