<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 16:20:06
 * @LastEditTime: 2021-09-29 15:15:03
 * @LastEditors: aleaner
 * @Description: 组织信息
 * @FilePath: \gov\components\business-association-list\detail\OrganizeInformation.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular organize-information">
    <h2 class="modular-title">组织信息</h2>
    <div class="modular-content">
      <div class="item" v-for="item in itemArr" :key="item.key">
        <div class="label">{{item.label}}：</div>
        <!-- logo -->
        <div v-if="item.key==='icon'" class="value">
          <CertView :src="data[item.key]" width="280px" height="120px" />
        </div>
        <!-- 其他 -->
        <div v-else class="value">
          {{item.prefix?item.prefix:""}}{{data[item.key]|placeholder}}{{item.suffix?item.suffix:""}}
          <el-button v-if="item.click" type="text" class="btn" @click="onClick(item.click)">
            {{item.click.name}}
          </el-button>
        </div>
      </div>
    </div>
    <!-- 修改星级弹窗 -->
    <ChangeStarDialog v-model="openChangeStarDialog" :editData="ChangeStarData" :complete="refreshData" />
  </div>
</template>

<script>
import CertView from "@/base/components/Preview/CertView.vue";
import ChangeStarDialog from "../list/ChangeStarDialog.vue";
export default {
  props: {
    //数据
    data: {
      type: Object,
      required: true,
    },
    // 修改星级成功后回调
    refreshData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      itemArr: [
        { label: "组织LOGO", key: "icon" },
        { label: "组织名称", key: "name" },
        {
          label: "星级",
          key: "star_text",
          click: { type: "edit_star", name: "修改" },
        },
        {
          label: "架构层级",
          key: "level_text",
          click: { type: "view_level", name: "查看架构图" },
        },
        // { label: "地区", key: "" },
        // { label: "行业类型", key: "" },
        // { label: "资金规模", key: "" },
        // { label: "组织标识", key: "" },
        // { label: "法人登记证书", key: "" },
        {
          label: "会员人数",
          key: "members_count",
          suffix: "人",
          click: { type: "view_members_count", name: "查看详情" },
        },
        {
          label: "资讯数量",
          key: "articles_count",
          suffix: "个",
          click: { type: "view_articles_count", name: "查看详情" },
        },
        {
          label: "资源数量",
          key: "circles_count",
          suffix: "个",
          click: { type: "view_circles_count", name: "查看详情" },
        },
        {
          label: "企业数量",
          key: "companies_count",
          suffix: "个",
          click: { type: "view_companies_count", name: "查看详情" },
        },
        {
          label: "活动数量",
          key: "activities_count",
          suffix: "个",
          click: { type: "view_activities_count", name: "查看详情" },
        },
        { label: "是否收会费", key: "member_fee_text" },
        { label: "最高会费", key: "max_fee", suffix: "元" },
        { label: "最低会费", key: "min_fee", suffix: "元" },
        {
          label: "会费收入",
          key: "member_fee_income",
          suffix: "元",
          click: { type: "view_member_fee_income", name: "查看详情" },
        },
        {
          label: "增值服务收入",
          key: "added_service_income",
          suffix: "元",
          click: { type: "view_added_service_income", name: "查看详情" },
        },
      ],
      openChangeStarDialog: false, // 是否显示修改星级弹窗
      ChangeStarData: {}, // 修改星级的数据
    };
  },
  methods: {
    //通用点击事件
    onClick({ type }) {
      switch (type) {
        case "edit_star": // 修改星级
          this.editStar();
          break;
        case "view_level": //查看层级架构图
          this.viewLeve();
          break;
        case "view_members_count": //会员数量
          this.JumpNewPage({
            name: "DataListMemberLibrary",
            query: { projectId: this.data.id },
          });
          break;
        case "view_articles_count": //资讯数量
          this.JumpNewPage({
            name: "DataListNewsLibrary",
            query: { projectId: this.data.id },
          });
          break;
        case "view_circles_count": //资源数量
          this.JumpNewPage({
            name: "DataListResourceLibrary",
            query: { projectId: this.data.id },
          });
          break;
        case "view_companies_count": //企业数量
          this.JumpNewPage({
            name: "DataListEnterpriseLibrary",
            query: { projectId: this.data.id },
          });
          break;
        case "view_activities_count": //活动数量
          this.JumpNewPage({
            name: "DataListActivityLibrary",
            query: { projectId: this.data.id },
          });
          break;
        default:
          this.$message.info("开发中...");
      }
    },
    // 跳转新标签页
    JumpNewPage(data) {
      let routerData = this.$router.resolve(data);
      window.open(routerData.href, "_blank");
    },
    // 修改星级
    editStar() {
      this.ChangeStarData = {
        id: this.data.id,
        star: this.data.star,
      };
      this.openChangeStarDialog = true;
    },
    //查看架构层级图
    viewLeve() {
      if (this.data.framework_image) {
        this.$previewImage([this.data.framework_image]);
      } else {
        this.$message.info("未上传架构图");
      }
    },
  },
  components: {
    CertView,
    ChangeStarDialog,
  },
};
</script>

<style lang="scss" scoped>
.organize-information {
  .item {
    display: flex;
    font-size: 14px;
    line-height: 22px;
    & + .item {
      margin-top: 20px;
    }
    .label {
      width: 100px;
      flex-shrink: 0;
      color: #303133;
      text-align: right;
      white-space: nowrap;
    }
    .value {
      width: 0;
      flex-grow: 1;
      color: #000;

      .btn {
        margin-left: 15px;
        padding: 0;
      }
    }
  }
}
</style>