<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 17:32:02
 * @LastEditTime: 2021-09-13 18:05:34
 * @LastEditors: mulingyuer
 * @Description: 历届详情
 * @FilePath: \gov-admin-vue\src\modules\business-association-list\components\detail\PreviousSituation.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular previous-situation">
    <h2 class="modular-title">历届详情</h2>
    <div class="modular-content">
      <el-table class="thead-light" :data="tbody" stripe style="width: 100%">
        <el-table-column v-for="th in thead" :key="th.prop" :label="th.label" :prop="th.prop"
          :width="th.width" :min-width="th.minWidth" :sortable="th.sortable" :fixed="th.fixed"
          show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span>
              {{th.prefix?th.prefix:""}}{{ row[th.prop]|placeholder }}{{th.suffix?th.suffix:""}}
              <el-button v-if="th.click" type="text" class="row-btn" @click="onClick(th.click)">
                {{th.click.name}}
              </el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //表格数据
    tbody: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      thead: [
        {
          label: "届（数）",
          prop: "term",
          minWidth: 120,
          prefix: "第 ",
          suffix: " 届",
        },
        { label: "周期时间", prop: "cycle_time", minWidth: 200 },
        { label: "架构层级", prop: "level_text", minWidth: 120 },
        {
          label: "会员人数",
          prop: "members_count",
          minWidth: 120,
          suffix: "人",
        },
        //  { label: "较比上届会员增长", prop: "id", minWidth: 120 },
        { label: "是否收会费", prop: "member_fee_text", minWidth: 120 },
        { label: "最高会费", prop: "max_fee", minWidth: 120, suffix: "元" },
        { label: "最低会费", prop: "min_fee", minWidth: 120, suffix: "元" },
        {
          label: "会费收入",
          prop: "member_fee_income",
          minWidth: 120,
          suffix: "元",
          click: { type: "view_member_fee_income", name: "详情" },
        },
        {
          label: "增值服务收入",
          prop: "added_service_income",
          minWidth: 120,
          suffix: "元",
          click: { type: "view_added_service_income", name: "详情" },
        },
      ],
    };
  },
  methods: {
    //通用点击事件
    onClick({ type }) {
      switch (type) {
        default:
          this.$message.info("开发中...");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.previous-situation {
  .modular-content {
    .row-btn {
      margin-left: 10px;
      padding: 0;
    }
  }
}
</style>
