<!--
 * @Author: dongjia
 * @Date: 2021-09-29 14:38:58
 * @LastEditTime: 2021-09-29 15:03:50
 * @LastEditors: aleaner
 * @Description: 修改星级弹窗
 * @FilePath: \gov\components\business-association-list\list\ChangeStarDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="修改星级" :visible.sync="show" width="500px" class="dialog-vertical check-view-dialog">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" size="medium"
        class="medium-form">
        <el-form-item label="选择星级：" prop="star">
          <el-select v-model="form.star" placeholder="请选择">
            <el-option v-for="item in starOptions" :label="item.name" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="medium" @click="show=false">取消
      </el-button>
      <el-button type="primary" size="medium" :loading="saveLoading" @click="onSave('ruleForm')">确定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { setStar } from "../../../api/business-association-list/list";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //修改的数据
    editData: {
      type: Object,
      required: true,
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        star: "",
      },
      saveLoading: false,
      rules: {
        star: [{ required: true, message: "请选择星级", trigger: "change" }],
      },
      starOptions: [
        { name: "暂未设置", id: 0 },
        { name: "一星商会", id: 1 },
        { name: "二星商会", id: 2 },
        { name: "三星商会", id: 3 },
        { name: "四星商会", id: 4 },
        { name: "五星商会", id: 5 },
      ],
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.ruleForm.resetFields();
          this.form.star = this.editData.star;
        });
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = {
            id: this.editData.id,
            star: this.form.star,
          };
          setStar(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.saveLoading = false;
              this.show = false;
              this.complete();
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
